export const PAGE_PATHS = {
  CONTENT_PERMISSIONS: 'content-permissions',
  MODEL_MANAGEMENT: 'model-management',
  DATA_UNIFICATION_FIELDS: 'fields',
  ACCOUNT_MANAGEMENT: 'account-management',
  ALL_ACCOUNTS: 'all-accounts',
  EDIT_MY_ACCOUNT_MANAGEMENT: 'account-settings',
  USER_MANAGEMENT: 'user-management',
  ACCOUNT_USAGE: 'account-usage',
  ALL_USERS: 'all-users',
  EDIT_MY_USER: 'profile-settings',
  GLOBAL_SCRIPTS: 'global-scripts',
  TARGETS: 'targets',
  LOGS: 'logs',
  ALERTS: 'alerts',
  RULES: 'rules',
  SOURCES: 'sources',
  TICKETS: 'tickets',
  INCIDENTS: 'incidents',
  FINDINGS: 'findings',
  ASSETS: 'assets',
  WORKFLOWS: 'workflows',
  QUERY: 'query',
  SETTINGS: 'settings',
  RULE_SET: 'rule-set',
  SEVERITY_SLA: 'severity-sla',
  TICKETS_STATUSES: 'tickets-statuses',
  UI_CONFIG: 'ui-config',
  ENTITIES: 'entities',
  LOOKUP_TABLES: 'lookup-tables',
  REMEDIATION: 'remediation',
  RISK: 'risk',
  ASSET_COVERAGE: 'asset-coverage',
  PIVOT: 'pivot',
  ROI: 'roi',
  OVERVIEW: 'overview',
  SCORE: 'score',
  TEST_QUERY: 'test-query',
  REPORTS: 'reports',
  BACKOFFICE_ACTIONS: 'backoffice-actions',
  CUSTOM_DASHBOARDS: 'dashboards',
  ALIASES: 'aliases',
  RELATIONS: 'relations',
  PERMISSIONS: 'permissions',
  ROLES: 'roles',
  SOURCES_MAPPING: 'data-mapping',
  MEASUREMENTS: 'measurements',
  FACTOR: 'factors',
  FACTOR_RULES: 'factor-rules',
  GLOBAL_FACTOR_RULES: 'global-factor-rules',
  ICONS: 'icons',
  POLICY_COMPLIANCE: 'policy-compliance',
  ASSET_INVENTORY: 'asset-inventory',
  POLICY_VIOLATIONS: 'policy-violations',
  POLICIES: 'policies',
  DATA_UNIFICATION: 'data-unification',
  DATA_UNIFICATION_ENTITIES: 'data-entities',
  DATA_UNIFICATION_ENTITY_MERGE: 'entity-merge',
  AUTHENTICATION_SOURCES: 'authentication-sources',
  EXCEPTIONS: 'exceptions',
  FORMATTING_RULES: 'formatting-rules',
  RISK360_BACKOFFICE_ACTIONS: 'risk360-backoffice-actions',
  TOOL_COVERAGE_AND_GAP: 'tool-coverage-and-gap',
};

export const APP_PATHS = {
  CAASM: 'caasm',
  INCIDENTS: 'incidents',
  VULNERABILITIES: 'vulnerabilities',
  DETECTIONS: 'detections',
  PLATFORM: 'platform',
  EXPLORE: 'explore',
  SETTINGS: 'settings',
  RISK360: 'risk360',
  THREATS: 'threats',
};

export type AppPathsValue = (typeof APP_PATHS)[keyof typeof APP_PATHS];
export const PUBLIC_APPS = [APP_PATHS.SETTINGS, APP_PATHS.EXPLORE, APP_PATHS.PLATFORM];
