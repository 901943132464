import { PAGE_PATHS } from './routes.types';

export const INTERNAL_ROLES = {
  RND_ADMIN: 'RND_ADMIN',
  SYS_ADMIN: 'SYS_ADMIN',
};

// TODO: cleanup after permissions is fully migrated
export const ROLES = {
  RND_ADMIN: 7,
  ADMIN: 5,
  EDITOR: 4,
  VIEWER: 3,
  SIEM_USER: 2,
  PRE_POV: 1,
  SYS_ADMIN: 18,
};

export const getRoleNameByValue = value => Object.keys(ROLES).find(key => ROLES[key] === value);

export const OLD_PREDEFINED_ROLE_NAMES = {
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  SIEM_USER: 'SIEM_USER',
};

export const NEW_PREDEFINED_ROLE_NAMES = { DEFAULT: 'DEFAULT', RND_ADMIN: 'RND_ADMIN', ADMIN: 'ADMIN' };
export const PREDEFINED_ROLE_NAMES = { ...OLD_PREDEFINED_ROLE_NAMES, ...NEW_PREDEFINED_ROLE_NAMES };
export const FeatureFlags = {
  ShowAssetsDashboardOverview: 'assets-overview-dashboard', // https://jira.corp.zscaler.com/browse/AVA-13728
  RefetchTicketOnFocus: 'refetch-ticket-on-window-focus', // https://jira.corp.zscaler.com/browse/AVA-13729
  ForceFixTextInTicketTab: 'force-fix-text-in-ticket-tab', // https://jira.corp.zscaler.com/browse/AVA-13730
  ManualUpdateTicketIntegration: 'manual-update-ticket-integration', // https://jira.corp.zscaler.com/browse/AVA-13731
  KeepSessionFilters: 'keep-session-filters', // https://jira.corp.zscaler.com/browse/AVA-13732
  createTicketFromAlerts: 'create-ticket-from-alerts', // https://jira.corp.zscaler.com/browse/AVA-13733
  ShowRoiDashboard: 'show-roi-dashboard', // https://jira.corp.zscaler.com/browse/AVA-13734
  ShowServerErrorInToast: 'show-server-error-in-toast', // https://jira.corp.zscaler.com/browse/AVA-13736
  TriggerIntegrationButton: 'trigger-integration-button', // https://jira.corp.zscaler.com/browse/AVA-13738
  disableCacheQueryClient: 'disable-cache-query-client', // https://jira.corp.zscaler.com/browse/AVA-13739
  QuerySQLDebounce: 'query-sql-debounce', // https://jira.corp.zscaler.com/browse/AVA-13740
  AssetMergingTab: 'asset-merging-tab', // https://jira.corp.zscaler.com/browse/AVA-13741
  ShowDataLakeMonths: 'ShowDataLakeMonths', // https://jira.corp.zscaler.com/browse/AVA-13742
  FindingView: 'finding-view', // https://jira.corp.zscaler.com/browse/AVA-13744
  ReportsHistorical: 'enable-reports-historical', // https://jira.corp.zscaler.com/browse/AVA-13745
  NewLogSearch: 'new-log-search', // https://jira.corp.zscaler.com/browse/AVA-13746
  CustomRolesPage: 'custom-roles-page', // https://jira.corp.zscaler.com/browse/AVA-13747
  MeasurementsPage: 'measurements-page', // https://jira.corp.zscaler.com/browse/AVA-13748
  FixTableDrillDown: 'fix-tab-drilldown-to-findings', // https://jira.corp.zscaler.com/browse/AVA-13776
  DataUnificationRules: `data-unification`, // https://jira.corp.zscaler.com/browse/AVA-13777
  EnableUnificationCEL: `enable-unification-cel`, // https://jira.corp.zscaler.com/browse/AVA-13778
  EnhancedClusteringCELUi: `enhanced-clustering-cel-ui`, // https://jira.corp.zscaler.com/browse/AVA-13779
  priorityBySourceEnabled: `priority-by-source-enabled`, // https://jira.corp.zscaler.com/browse/AVA-13780
  RemoveBackslashEscapingFromWhere: 'remove-backslash-escaping-from-where', // https://jira.corp.zscaler.com/browse/AVA-13781
  BatchAggregation: 'batch-aggregation', // https://jira.corp.zscaler.com/browse/AVA-13782
  ShowDrillDown: 'show-drill-down', // https://jira.corp.zscaler.com/browse/AVA-13783
  HistoricalAnalytics: 'historical-analytics', // https://jira.corp.zscaler.com/browse/AVA-13784
  ShowOrgEntityPane: 'show-org-entity-pane', // https://jira.corp.zscaler.com/browse/AVA-13785
  InvestigateEntity: 'investigate-entity', // https://jira.corp.zscaler.com/browse/AVA-13786
  VisualExplorerRealRelations: 'visual-explorer-real-relations', // https://jira.corp.zscaler.com/browse/AVA-13787
  AllowEmptyAssignmentOnUnification: 'support-empty-assignment-unification-rules', // https://jira.corp.zscaler.com/browse/AVA-13788
  accountUsagePage: 'account-usage-page', // https://jira.corp.zscaler.com/browse/AVA-13789
  FindingsViewManualGrouping: 'findings-view-manual-grouping', // https://jira.corp.zscaler.com/browse/AVA-13790
  KeepSlaWithoutFormatting: 'keep-sla-without-formatting', // https://jira.corp.zscaler.com/browse/AVA-13791
  autoMapping: 'auto-mapping', // https://jira.corp.zscaler.com/browse/AVA-13792
  AgingRules: 'aging-rules', // https://jira.corp.zscaler.com/browse/AVA-13793
  QueryObjectEditor: 'widget-query-object-editor', // https://jira.corp.zscaler.com/browse/AVA-13794
  NewRolesForUserMgmPage: 'new-roles-for-user-mgm', // https://jira.corp.zscaler.com/browse/AVA-13795
  ShowAliasPage: 'show-alias-page', // https://jira.corp.zscaler.com/browse/AVA-13796
  ShowMeasurementsPage: 'show-measurements-page', // https://jira.corp.zscaler.com/browse/AVA-13797
  NewLogsScreen: 'new-logs-screen', // https://jira.corp.zscaler.com/browse/AVA-13799
  OneAuthUI: 'one-auth-ui', // https://jira.corp.zscaler.com/browse/AVA-13800
  TileWidgetSupportsText: 'tile-widget-supports-text', // https://jira.corp.zscaler.com/browse/AVA-13801
  OutegrationQueryObjectAttachment: 'attachment-as-query-object-for-outegrations', // https://jira.corp.zscaler.com/browse/AVA-13802
  EnableZscalerConfigEdit: 'enable-zscaler-config-edit', // https://jira.corp.zscaler.com/browse/AVA-13803
  Rebranding: 'rebranding', // https://jira.corp.zscaler.com/browse/AVA-13805
  OutegrationsForAdmins: 'outegrations-for-admins', // https://jira.corp.zscaler.com/browse/AVA-13806
  GetActivityLogsFromHistoryTable: 'get-activity-logs-from-history-table', // https://jira.corp.zscaler.com/browse/AVA-13807
  TopFields: 'top-fields', // https://jira.corp.zscaler.com/browse/AVA-13809
  Exceptions: 'exception-entity-enabled', // https://jira.corp.zscaler.com/browse/AVA-13810
  ProgressAndTrendWidgets: 'progress-and-trend-widgets', // https://jira.corp.zscaler.com/browse/AVA-13811
  NewFieldsToolbar: 'new-fields-toolbar', // https://jira.corp.zscaler.com/browse/AVA-13812
  ClickthroughRelatedFields: 'clickthrough-related-field', // https://jira.corp.zscaler.com/browse/AVA-11915
  HistoricalAsync: 'historical-async', // https://jira.corp.zscaler.com/browse/AVA-13813
  DefaultFlatSchemaProvisioning: 'default-flat-schema-provisioning', // https://jira.corp.zscaler.com/browse/AVA-13815
  PopulatedFields: 'populated-fields', // https://jira.corp.zscaler.com/browse/AVA-13877
  ShowFormattingRules: 'show-formatting-rules', // https://jira.corp.zscaler.com/browse/AVA-13901
  RelationsPage: 'relations-page', // https://jira.corp.zscaler.com/browse/AVA-13956
  ScoreExplanationUpdatedDesign: 'score-explanation-updates', // https://jira.corp.zscaler.com/browse/AVA-13935
  OneAuthOutegrations: 'one-auth-outegrations', // https://jira.corp.zscaler.com/browse/AVA-11906
  PermissionsForUpdateDefaultMapping: 'permission-to-update-default-mapping', // https://jira.corp.zscaler.com/browse/AVA-11038
  RunsScreenErrorInfo: 'runs-screen-error-info', // https://jira.corp.zscaler.com/browse/AVA-11334
  SourceValuesUIConfig: 'ingress-values-ui-config', // https://jira.corp.zscaler.com/browse/AVA-14187

};

export const PathFeatureFlag = {
  [PAGE_PATHS.ROI]: FeatureFlags.ShowRoiDashboard,
  [PAGE_PATHS.FINDINGS]: FeatureFlags.FindingView,
  [PAGE_PATHS.EXCEPTIONS]: FeatureFlags.Exceptions,
  [PAGE_PATHS.LOGS]: FeatureFlags.NewLogSearch,
  [PAGE_PATHS.ROLES]: FeatureFlags.CustomRolesPage,
  [PAGE_PATHS.MEASUREMENTS]: FeatureFlags.MeasurementsPage,
  [PAGE_PATHS.DATA_UNIFICATION_FIELDS]: FeatureFlags.DataUnificationRules,
  [PAGE_PATHS.DATA_UNIFICATION]: FeatureFlags.DataUnificationRules,
  [PAGE_PATHS.ACCOUNT_USAGE]: FeatureFlags.accountUsagePage,
  [PAGE_PATHS.AUTHENTICATION_SOURCES]: FeatureFlags.OneAuthUI,
  [PAGE_PATHS.FORMATTING_RULES]: FeatureFlags.ShowFormattingRules,
  [PAGE_PATHS.RELATIONS]: FeatureFlags.RelationsPage,
};

export enum Permission {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum PermissionEntitiesNames {
  // Platform
  MODEL = 'MODEL',
  SOURCE = 'SOURCE',
  SOURCE_MAPPING = 'SOURCE_MAPPING',
  WORKFLOW_SCRIPT = 'WORKFLOW_SCRIPT',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  VIEWS = 'VIEWS',
  ROLE = 'ROLE',
  INTEGRATION = 'INTEGRATION',
  ALIAS = 'ALIAS',
  ZCONFIG = 'ZCONFIG',
  AUTHENTICATION = 'AUTHENTICATION',

  // Explore
  SEARCH = 'SEARCH',
  REPORT = 'REPORT',

  // Multiple Apps
  ANALYTICS = 'ANALYTICS',
  EXCEPTION = 'EXCEPTION',

  // Entity
  OPT = 'OPT',
  SETTINGS = 'SETTINGS',
  INT = 'INT',
  QUERY_RUNNER = 'QUERY_RUNNER',

  // Detection App
  RULE = 'RULE',
  GLOBAL_SCRIPT = 'GLOBAL_SCRIPT',
  LOOKUP_TABLE = 'LOOKUP_TABLE',

  // Risk 360 App
  EXE_RULE = 'EXE_RULE',
  BUILTIN_EXE_RULE = 'BUILTIN_EXE_RULE',

  // Backoffice
  BACKOFFICE = 'BACKOFFICE',
}

export enum PermissionAppName {
  PLATFORM = 'PLATFORM',
  EXPLORE = 'EXPLORE',
  ENTITY = 'ENTITY',
  VULNERABILITIES = 'VULNERABILITIES',
  DETECTIONS = 'DETECTIONS',
  INCIDENTS = 'INCIDENTS',
  CAASM = 'CAASM',
  RISK360 = 'RISK360',
}

export enum PermissionModelEntityName {
  TICKET = 'TICKET',
  FINDING = 'FINDING',
  ASSET = 'ASSET',
  INCIDENT = 'INCIDENT',
  ALERT = 'ALERT',
  FACTOR = 'FACTOR',
  POLICYPOPULATION = 'POLICYPOPULATION',
  EXCEPTION = 'EXCEPTION',
}

export type PermissionCategoriesNames = PermissionAppName | PermissionModelEntityName;

export type PermissionForRoute =
  | {
      category?: PermissionCategoriesNames;
      permissionEntity: PermissionEntitiesNames;
    }
  | { requiredRole: keyof typeof PREDEFINED_ROLE_NAMES };
